import React from 'react'
import Layout from '../../components/layout'

import { Link } from 'gatsby';
import qapps_features from '../../data/qapps_features.json';
import qapps_why from '../../data/qapps_why.json';

import { StaticImage } from "gatsby-plugin-image"
import Seo from '../../components/seo';

export default function QApps() {
    const qapps_hero = `../../assets/images/products/qapps/qboard-left.png`;
    const qapps_hero2 = '../../assets/images/products/qapps/qapps1.png';
    const qapps_hero3 = '../../assets/images/products/qapps/qmobile1.png';
    const qapps_hero4 = '../../assets/images/products/qapps/qmobile2.png';
    const qapps_hero5 = '../../assets/images/products/qapps/qmobile3.png';
    const button_playstore = '../../assets/images/button_playstore.png';
    const button_appstore = '../../assets/images/button_appstore.png';
    const android_link = "https://play.google.com/store/apps/details?id=com.innovations.timefree.qmobile";
    const ios_link = "https://apps.apple.com/ph/app/timefree-qmobile/id986245271";
    return (
        <Layout>
            <Seo title="QApps" />
            <div id="qapps_hero_section" className="flex items-center qapps-hero-card m-4 lg:m-16 lg:mt-10 2xl:mx-28 h-full relative z-30 overflow-visible relative">
                {/* <div className="flex hero-card p-4 lg:p-16 lg:mt-10 h-full relative z-30 overflow-visible relative"> */}
                <div id="qapps_hero" className="z-30 pb-10 md:pb-20">
                    {/* <main className="mt-10 w-full sm:w-8/12 md:w-1/2 lg:w-7/12 xl:w-8/12 3xl:w-11/12 px-6 sm:px-8 lg:px-16 sm:mt-12 md:mt-16 lg:mt-20 xl:mt-28"> */}
                    <main className="mt-10 w-full px-6 sm:px-8 lg:px-16 sm:mt-12 md:mt-16 lg:mt-20 xl:mt-28">
                        <div className="text-center md:text-left">
                            <h1 className="font-extrabold text-dark-tf text-3xl sm:text-4xl md:text-5xl lg:text-5xl xl:text-6xl w-fulltracking-wide ">
                                <span className="block xl:inline leading-relaxed md:leading-14 lg:leading-15">Change the way you manage long lines</span>
                                {/* <span className="block text-blue-tf xl:inline leading-relaxed md:leading-14 lg:leading-15"> TimeFree</span> */}
                                {/* <span className="block xl:inline leading-relaxed md:leading-14 lg:leading-15"> digital solutions</span> */}
                            </h1>
                            <p className="mt-5 sm:mt-5 md:mt-10 lg:mt-15 pl-1 font-normal text-notsodark-tf text-lg md:text-xl xl:text-2xl 4xl:text-3xl lg:mx-0 leading-relaxed md:leading-loose xl:leading-loose">
                                Fix it with <strong className="text-blue-tf">QApps</strong> and <strong className="text-blue-tf">QMobile</strong> virtual queuing system<i> – the smarter way to manage long lines.</i>
                            </p>
                            {/* <p className="mt-5 sm:mt-5 md:mt-10 lg:mt-15 pl-1 font-normal text-notsodark-tf text-lg md:text-xl xl:text-2xl 4xl:text-3xl lg:mx-0 leading-relaxed md:leading-loose xl:leading-loose">
                                <strong className="text-blue-tf">QApps</strong> is a queue management solution that enables your business to manage high customer traffic in your store or office and monitor staff productivity and efficiency.
                                <br /><br /><strong className="text-blue-tf">QMobile</strong> is queue slot reservation and appointment booking app to help your business provide excellent customer queuing experience and increase customer engagements.
                            </p> */}
                        </div>
                        <div className="mt-8 flex lg:mt-10 lg:flex-shrink-0 justify-center md:justify-start">
                            <div className="inline-flex rounded-md shadow-xl shadow-blue-tf/20">
                                <Link to="/contact" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-lg md:text-xl 4xl:text-3xl font-medium rounded-md text-white bg-blue-tf hover:bg-darkblue-tf">
                                    Request a demo
                                </Link>
                            </div>
                        </div>
                    </main>
                </div>
                {/* <div id="qapps_hero_image" className="filter drop-shadow-xl flex justify-end overflow-visible absolute top-20 xxs:top-20 sm:top-0 md:top-10 lg:top-10 2xl:-top-10 right-0 lg:right-5 xl:-right-10 w-7/12 sm:w-7/12 lg:w-1/2 hidden sm:block z-10 opacity-50 xs:opacity-100"> */}
                <div id="qapps_hero_image" className="filter drop-shadow-xl flex self-center overflow-visible w-full 2.5xl:w-1/2 3xl:w-1/2 4xl:w-1/3 hidden md:block z-10 opacity-50 xs:opacity-100">
                    <StaticImage
                        className="flex object-contain w-full"
                        src={qapps_hero}
                        placeholder="tracedSVG"
                        alt=""
                    />
                </div>
            </div>

            <div id="qapps_hero_image_alt" className="block md:hidden w-full flex justify-center">
                <StaticImage
                    className="flex object-contain overflow-visible w-8/12"
                    src={qapps_hero}
                    placeholder="tracedSVG"
                    alt=""
                />
            </div>

            <div id="qapps_features" className="bg-white py-8 mt-10">
                <div className="flex flex-col items-center m-4 lg:m-16 2xl:mx-28">
                    <div className="w-3/4 md:w-5/7">
                        <h4 className="text-3xl 4xl:text-4xl font-bold text-dark-tf text-center">Packed with powerful features, but easy to use.</h4>
                        <p className="text-xl 4xl:text-2xl text-dark-tf text-center mt-3">Overcrowding. Irate customers waiting in line. Customers walking away because they have waited long enough. These are just some of the problems that businesses face. <br />It’s time to change this with <strong className="text-blue-tf">QApps</strong> + <strong className="text-blue-tf">QMobile</strong>!</p>
                    </div>
                    <div className="mt-16 flex flex-wrap">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 flex w-full xl:w-7/12 justify-between items-stretch">
                            {qapps_features.map((item) => (
                                <div key={item.title}>
                                    <div className="text-center md:text-left bg-qapps-blue p-6 rounded-2xl self-stretch h-full md:flex gap-x-5">
                                        <div className="flex justify-center md:items-start">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-darkblue-tf" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                {item.svg.map((svg, i) => (
                                                    <path key={i.toString()} strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.2} d={svg} />
                                                ))}
                                            </svg>
                                        </div>
                                        <div className="">
                                            <h3 className="head-font font-semibold text-xl 4xl:text-3xl text-darkblue-tf">{item.title}</h3>
                                            <p className="font-normal text-lg 4xl:text-2xl mt-2 text-gray-700">{item.subtitle}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="filter drop-shadow-xl flex w-full h-full xl:w-5/12 justify-end mt-10 px-10 md:px-28 xl:px-6">
                            <StaticImage
                                className="flex object-contain overflow-visible w-full"
                                src={qapps_hero2}
                                placeholder="tracedSVG"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* <div id="qapps_why" className="mx-4 lg:mx-16 my-28 2xl:mx-28">
                <div className="flex flex-col items-center ">
                    <div className="w-full">
                        <h4 className="text-3xl font-bold text-dark-tf text-center">Why do businesses choose <strong className="text-blue-tf">QApps</strong> + <strong className="text-blue-tf">QMobile</strong>?</h4>
                    </div>
                    <div className="mt-10 flex-none lg:flex">
                        <div className="flex justify-center h-1/4 w-full items-center ">
                            <StaticImage
                                className="flex object-contain overflow-visible md:w-1/3 lg:w-5/12 lg:-right-10"
                                src={qapps_hero3}
                                placeholder="tracedSVG"
                                alt=""
                            />
                            <StaticImage
                                className="flex object-contain overflow-visible md:w-1/3 lg:w-5/12 lg:top-10 lg:-left-24 filter drop-shadow-lg"
                                src={qapps_hero4}
                                placeholder="tracedSVG"
                                alt=""
                            />
                        </div>
                        <div className="grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-8 flex justify-between items-stretch ">
                            {qapps_why.map((item) => (
                                <div key={item.key}>
                                    <div className="text-center md:text-left p-6 rounded-2xl self-stretch h-full md:flex gap-x-5">
                                        <div className="flex justify-center md:items-start pt-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-darkblue-tf" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                {item.svg.map((svg, i) => (
                                                    <path key={i.toString()} strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.2} d={svg} />
                                                ))}
                                            </svg>
                                        </div>
                                        <div className="">
                                            <p className="font-medium text-xl mt-1 text-gray-700">{item.subtitle}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div> */}

            <div id="qapps_why" className="mx-4 lg:mx-16 my-28 2xl:mx-28">
                <div className="flex flex-wrap">
                    <div className="w-full">
                        <h4 className="text-3xl 4xl:text-4xl font-bold text-dark-tf text-center">Why do businesses choose <strong className="text-blue-tf">QApps</strong> + <strong className="text-blue-tf">QMobile</strong>?</h4>
                    </div>
                    <div className="mt-10 block md:hidden flex flex-col w-full">
                        <div className="flex justify-center items-center">
                            <StaticImage
                                className="flex object-contain overflow-visible md:w-1/3 lg:w-5/12 lg:-right-10"
                                src={qapps_hero3}
                                placeholder="tracedSVG"
                                alt=""
                            />
                            <StaticImage
                                className="flex object-contain overflow-visible md:w-1/3 lg:w-5/12 lg:top-10 lg:-left-24 filter drop-shadow-lg"
                                src={qapps_hero4}
                                placeholder="tracedSVG"
                                alt=""
                            />
                        </div>
                        <div className="flex w-full justify-center mt-5">
                            <Link to={ios_link} className="w-1/3 shadow-md mr-1">
                                <StaticImage
                                    className="flex object-cover overflow-visible"
                                    src={button_appstore}
                                    placeholder="tracedSVG"
                                    alt=""
                                />
                            </Link>
                            <Link to={android_link} className="w-1/3 shadow-md ml-1">
                                <StaticImage
                                    className="flex object-cover overflow-visible  "
                                    src={button_playstore}
                                    placeholder="tracedSVG"
                                    alt=""
                                />
                            </Link>
                        </div>
                    </div>
                    <div className="flex flex-wrap w-full md:w-1/3 justify-between items-stretch">
                        {qapps_why.slice(0, 2).map((item) => (
                            <div key={item.key}>
                                <div className="text-center p-6 rounded-2xl self-stretch h-full gap-x-5">
                                    <div className="flex justify-center md:items-start pt-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-darkblue-tf" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            {item.svg.map((svg, i) => (
                                                <path key={i.toString()} strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.2} d={svg} />
                                            ))}
                                        </svg>
                                    </div>
                                    <div className="">
                                        <p className="font-medium text-xl 4xl:text-2xl mt-1 text-gray-700">{item.subtitle}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="mt-10 hidden md:block flex flex-wrap w-1/3 items-center">
                        <div className="flex flex-col justify-center items-center h-full">
                            <StaticImage
                                className="flex object-cover overflow-visible w-7/12"
                                src={qapps_hero5}
                                placeholder="tracedSVG"
                                alt=""
                            />
                            <div className="flex w-full justify-center">
                                <Link to={ios_link} className="w-1/3 shadow-md mr-1">
                                    <StaticImage
                                        className="flex object-cover overflow-visible"
                                        src={button_appstore}
                                        placeholder="tracedSVG"
                                        alt=""
                                    />
                                </Link>
                                <Link to={android_link} className="w-1/3 shadow-md ml-1">
                                    <StaticImage
                                        className="flex object-cover overflow-visible  "
                                        src={button_playstore}
                                        placeholder="tracedSVG"
                                        alt=""
                                    />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap w-full md:w-1/3 justify-between items-stretch ">
                        {qapps_why.slice(2, 4).map((item) => (
                            <div key={item.key}>
                                <div className="text-center p-6 rounded-2xl self-stretch h-full gap-x-5">
                                    <div className="flex justify-center md:items-start pt-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-darkblue-tf" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            {item.svg.map((svg, i) => (
                                                <path key={i.toString()} strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.2} d={svg} />
                                            ))}
                                        </svg>
                                    </div>
                                    <div className="">
                                        <p className="font-medium text-xl 4xl:text-2xl mt-1 text-gray-700">{item.subtitle}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div id="qapps_cta" className="bg-white">
                <div className="max-w-4xl mx-4 md:mx-auto py-12 px-4 lg:py-16 lg:px-16 lg:flex lg:items-center lg:justify-between">
                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl text-center md:text-left">
                        <span className="block text-gray-700">Ready to give this a try?</span>
                        <span className="block text-darkblue-tf"><Link to="/contact">Request a demo now.</Link></span>
                    </h2>
                    <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0 justify-center md:justify-start">
                        <div className="inline-flex rounded-md shadow-xl shadow-darkblue-tf/20">
                            <Link to="/contact" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-lg md:text-xl 4xl:text-3xl font-medium rounded-md text-white bg-darkblue-tf hover:bg-blue-tf"                        >
                                Contact us
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
